/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import {
  Table,
  TableBody,
  TableCell,
  Card,
  Box,
  Grid,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Divider,
  Paper,
  Checkbox,
  Button,
  Stack,
  IconButton,
  Collapse,
  Typography,
  CircularProgress,
} from '@mui/material';

// eslint-disable-next-line import/no-extraneous-dependencies
import { CSVLink } from 'react-csv';

import { titleCase, removeTitleCase, removeItemOnce } from '../../utils/core';

import Search from '../search/Search';
import Iconify from '../iconify/Iconify';
import DateRangePicker from '../date-picker/DateRangePicker';
import MultipleSelect from '../select/MultipleSelect';
import CollapseTableRow from './CollapseTableRow';
import InputForm from '../input-field/InputField';
import DatePickerInput from '../date-picker/DatePicker';
import SelectInput from '../select/SelectInput';
import { useLocation } from 'react-router-dom';
import MultiSelectInput from '../select/MulltiSelect';

// -------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator, isSortable) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  if (isSortable) {
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
  }
  return stabilizedThis.map((el) => el[0]);
}

// Table head
function SmartTabledHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    isRowSelectable,
    refreshAction,
    visibleRows,
    numSelected,
    selected,
    rowCount,
    onRequestSort,
    columnHeader,
    defaultColumn,
    onRefreshClicked,
    sortOrder,
    replaceAction,
    cxAction,
  } = props;
  //function for refresh all button
  const { pathname } = useLocation();

  const handleRefreshAll = (e) => {
    if (selected.length > 0) {
      const filteredSelected = selected.filter((trackingNumber) => {
        const selectedItem = visibleRows.find((item) => item.tracking_number === trackingNumber);
        return selectedItem && !(selectedItem.scanned_status === 'Delivered');
      });
      onRefreshClicked(e, filteredSelected, true);
    } else {
      const filteredRows = visibleRows.filter((item) => !(item.scanned_status === 'Delivered'));
      const filteredTrackingNumbers = filteredRows.map((item) => item.tracking_number);
      onRefreshClicked(e, filteredTrackingNumbers, true);
    }
  };

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
    if (pathname.startsWith('/dashboard/tracking') || pathname.startsWith('/dashboard/reports')) {
      sortOrder(property, order);
    }
  };
  const StyledTableHead = styled(TableHead)(({ theme }) => ({
    '& .MuiTableRow-head > .MuiTableCell-head': {
      color: theme.palette.common.black,
    },
    '& .MuiTableRow-head > .MuiTableCell-head> .MuiTableSortLabel-root:hover': {
      color: theme.palette.common.black,
    },
  }));

  const StyledTableCell = styled(TableCell)(() => ({
    width: 'auto',
    fontFamily: 'Whitney Semibold',
    fontSize: '16px',
    fontWeight: '500',
  }));

  return (
    <StyledTableHead>
      <TableRow>
        {isRowSelectable ? (
          <>
            <StyledTableCell padding="checkbox">
              <Checkbox
                color="primary"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{
                  'aria-label': 'select all desserts',
                }}
              />
            </StyledTableCell>
            <StyledTableCell
              key={`${defaultColumn}1`}
              align="left"
              padding="normal"
              sx={{ paddingLeft: 0 }}
              sortDirection={orderBy == { defaultColumn } ? order : false}
            >
              <TableSortLabel
                active={false}
                direction={orderBy == { defaultColumn } ? order : 'asc'}
                onClick={createSortHandler(defaultColumn)}
                sx={{ width: 'max-content' }}
              >
                {titleCase(defaultColumn)}
              </TableSortLabel>
            </StyledTableCell>
          </>
        ) : (
          <StyledTableCell
            key={`${defaultColumn}1`}
            align="left"
            padding="none"
            sx={{ paddingLeft: 5, width: 200 }}
            sortDirection={orderBy == { defaultColumn } ? order : false}
          >
            <TableSortLabel
              active={false}
              direction={orderBy == { defaultColumn } ? order : 'asc'}
              onClick={createSortHandler(defaultColumn)}
              sx={{ width: 160 }}
            >
              {titleCase(defaultColumn)}
            </TableSortLabel>
          </StyledTableCell>
        )}
        {columnHeader.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align="left"
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={false}
              sx={{ width: '200px' }}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label.replace('Per', '%')}
            </TableSortLabel>
          </StyledTableCell>
        ))}
        {cxAction && (
          <StyledTableCell
            key={`Actions`}
            align="center"
            padding="normal"
            sx={{
              position: 'sticky',
              right: 161,
              fontFamily: 'Whitney Semibold',
              fontSize: '16px',
              fontWeight: '500',
              paddingRight: { lg: 3 },
            }}
            sortDirection={orderBy == { defaultColumn } ? order : false}
          >
            Command Link
          </StyledTableCell>
        )}
        {refreshAction && (
          <StyledTableCell
            key={defaultColumn}
            align="right"
            padding="10px"
            sx={{
              position: 'sticky',
              right: 0,
              fontFamily: 'Whitney Semibold',
              fontSize: '16px',
              fontWeight: '500',
              paddingRight: { lg: 3 },
            }}
          >
            <Button
              variant="outlined"
              onClick={(e) => handleRefreshAll(e)}
              sx={{
                width: '129px',
                height: '45px',
                color: 'primary.dark',
                display: 'inline-flex',
                alignItems: 'center',
                gap: '10px',
                borderRadius: '12px',
                border: '1px solid primary.dark',
              }}
            >
              Refresh All
              <img width="18px" src="/assets/icons/table/refresh_green.svg" alt="" />
            </Button>
          </StyledTableCell>
        )}

        {replaceAction && (
          <StyledTableCell
            key={`Actions`}
            align="center"
            padding="normal"
            sx={{
              position: 'sticky',
              right: 0,
              fontFamily: 'Whitney Semibold',
              fontSize: '16px',
              fontWeight: '500',
              paddingRight: { lg: 3 },
            }}
            sortDirection={orderBy == { defaultColumn } ? order : false}
          >
            Actions
          </StyledTableCell>
        )}
      </TableRow>
    </StyledTableHead>
  );
}

SmartTabledHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  selected: PropTypes.array,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  columnHeader: PropTypes.array.isRequired,
  defaultColumn: PropTypes.string.isRequired,
  isRowSelectable: PropTypes.bool,
  refreshAction: PropTypes.bool,
  onRefreshClicked: PropTypes.func,
  sortOrder: PropTypes.func,
  replaceAction: PropTypes.bool,
  cxAction: PropTypes.bool,
};

// Table Toolbar
function SmartTabledToolbar(props) {
  const {
    numSelected,
    selected,
    onhandeSubmit,
    searchKey,
    exportCSV,
    onDateChange,
    columnsDetails,
    selectedColumns,
    handleColumnSubmit,
    isSearch,
    isAdvancedFilter,
    onSearchClick,
    startDate,
    endDate,
    onClickAdvancedFilter,
    showAdvancedFilter,
    isGridSelection,
    dateTypeList,
    onChangeAdvancedForm,
    advancedFormData,
    exportButtonClicked,
    trackingPage,
    userSelectedColumns,
    showFileUpload,
    onFileUpload,
    isReplacementPage,
    isDateRangePicker,
    isExportEnable,
  } = props;

  const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    paddingLeft: '0 !important',
    paddingRight: '0 !important',
    direction: 'row',
    alignItems: 'center',
    marginBottom: 10,
    justifyContent: 'space-between',
    [theme.breakpoints.down('lg')]: {
      alignItems: 'flex-end',
    },
  }));

  function generateFileName() {
    const currentTime = new Date();
    const year = currentTime.getFullYear();
    const month = String(currentTime.getMonth() + 1).padStart(2, '0'); // Add 1 to the month since it's zero-based
    const day = String(currentTime.getDate()).padStart(2, '0');
    const hours = String(currentTime.getHours()).padStart(2, '0');
    const minutes = String(currentTime.getMinutes()).padStart(2, '0');
    const seconds = String(currentTime.getSeconds()).padStart(2, '0');

    return `Shipping_insights_${year}-${month}-${day}_${hours}_${minutes}_${seconds}.csv`;
  }
  const filename = generateFileName();

  return (
    <StyledToolbar>
      <Grid
        container
        columnSpacing={{
          xs: 1,
          sm: 0,
          md: 1,
          lg: 1,
        }}
        rowSpacing={{ lg: 2, md: 1, sm: 1 }}
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: isSearch ? 'space-between' : 'space-between',
          my: 2,
          width: '100%',
          '& > .lastItem': {
            marginLeft: !isSearch ? 'auto' : 0,
          },
        }}
      >
        {isSearch && (
          <Grid item sm={12} md={12} lg="auto" display="flex" gap={0.5}>
            <Search
              size="small"
              onSubmitClicked={onhandeSubmit}
              searchValue={searchKey}
              onSearchClick={onSearchClick}
              sx={{ width: { lg: 222, sm: 180 } }}
            />
            {showFileUpload && (
              <Button
                variant="contained"
                onClick={onFileUpload}
                sx={{
                  boxShadow: 'none',
                  backgroundColor: 'primary.light',
                  minWidth: 40,
                  height: { lg: 40, sm: 33 },
                  // fontWeight: 500,
                  fontFamily: 'Whitney',
                  fontSize: { md: 16, sm: 14 },
                  letterSpacing: '0.1px',
                  marginRight: { md: '-2px' },
                  ':hover': {
                    backgroundColor: '#479da6d6',
                  },
                  padding: '6px 12px',
                }}
              >
                <Iconify icon="fluent:document-arrow-up-16-regular" width={25} />
              </Button>
            )}
          </Grid>
        )}

        {isAdvancedFilter && (
          <Grid item xs="auto" sx={{ marginRight: { xl: '120px', lg: '10px' } }}>
            <Button
              variant="contained"
              className={showAdvancedFilter && 'btn-active'}
              onClick={onClickAdvancedFilter}
              type="button"
              sx={{
                boxShadow: 'none',
                color: 'primary.light',
                width: { md: 184, sm: 148 },
                height: { lg: 40, sm: 33 },
                // marginLeft: {lg: "-2px"},
                // margin: 0,
                fontFamily: 'Whitney',
                letterSpacing: '0.15px',
                lineHeight: '24px',
                fontSize: { sm: 13, lg: 16 },
                backgroundColor: 'common.white',
                '&.btn-active, :hover': {
                  color: 'common.white',
                  backgroundColor: 'primary.light',
                },
              }}
              startIcon={<Iconify icon="material-symbols:instant-mix" />}
            >
              Advanced Filter
            </Button>
          </Grid>
        )}
        {trackingPage && (
          <Grid item sx={{ width: '200px', marginLeft: { md: '-14px', lg: '100px', xl: '20px' } }}>
            <MultipleSelect
              columnsDetails={columnsDetails}
              selectedColumns={userSelectedColumns}
              handleColumnSubmit={handleColumnSubmit}
            />
          </Grid>
        )}
        {isGridSelection && (
          <SelectInput
            sx={{
              marginTop: 0,
              height: { lg: 40, md: 40, sm: 35 },
              '&  .MuiFormControl-root': { width: { lg: 256, md: 200, sm: 150 } },
              '&  .MuiFormControl-root > .MuiInputBase-root': { height: { lg: 40, md: 33, sm: 33 } },
            }}
            name="report_type"
            onChangeAdvancedForm={onChangeAdvancedForm}
            advancedFormData={advancedFormData}
            listItem={dateTypeList}
          />
        )}

        {!trackingPage && (
          <Grid
            item
            xs="auto"
            sx={{
              width: '148px',
              paddingRight: { sm: '10px' },
              marginLeft: { xl: '200px', lg: '100px', md: '-70px', sm: '-60px' },
              marginRight: { sm: '30px' },
            }}
          >
            <MultipleSelect
              columnsDetails={columnsDetails}
              selectedColumns={userSelectedColumns}
              handleColumnSubmit={handleColumnSubmit}
            />
          </Grid>
        )}
        {isDateRangePicker && (
          <Grid item xs="auto">
            <DateRangePicker
              sx={{ height: { lg: 40, sm: 33 }, marginLeft: { sm: '-40px', md: '-10px' }, marginRight: { lg: '0px' } }}
              onDateChange={onDateChange}
              startDate={startDate}
              endDate={endDate}
            />
          </Grid>
        )}
        {isExportEnable && (
          <Grid item xs="auto">
            <Stack direction="row" spacing={2} justifyContent="space-between">
              {trackingPage && numSelected == 0 ? (
                <Button
                  variant="contained"
                  disabled={columnsDetails.length === 0}
                  sx={{
                    marginLeft: { xl: '-20px' },
                    boxShadow: 'none',
                    backgroundColor: 'primary.light',
                    width: { md: 132, sm: 95 },
                    height: { lg: 40, sm: 33 },
                    // fontWeight: 500,
                    fontFamily: 'Whitney',
                    fontSize: { md: 16, sm: 14 },
                    letterSpacing: '0.1px',
                    ':hover': {
                      backgroundColor: '#479da6d6',
                    },
                  }}
                  onClick={() => {
                    exportButtonClicked();
                  }}
                  startIcon={<Iconify icon="ic:outline-sim-card-download" />}
                >
                  Export
                </Button>
              ) : columnsDetails.length !== 0 ? (
                <CSVLink
                  style={{ color: '#FFF', textDecoration: 'none', display: 'flex' }}
                  data={exportCSV}
                  filename={filename}
                >
                  <Button
                    variant="contained"
                    sx={{
                      marginLeft: { lg: '50px', md: '-1px', sm: '40px' },
                      boxShadow: 'none',
                      backgroundColor: 'primary.light',
                      width: { md: 132, sm: 95 },
                      height: { lg: 40, sm: 33 },
                      // fontWeight: 500,
                      fontFamily: 'Whitney',
                      fontSize: { md: 16, sm: 14 },
                      letterSpacing: '0.1px',
                      marginRight: { md: '-2px' },
                      ':hover': {
                        backgroundColor: '#479da6d6',
                      },
                    }}
                    startIcon={<Iconify icon="ic:outline-sim-card-download" />}
                  >
                    Export
                  </Button>
                </CSVLink>
              ) : (
                <Button
                  variant="contained"
                  disabled={columnsDetails.length === 0}
                  sx={{
                    marginLeft: { lg: '50px', md: '-1px', sm: '40px' },
                    boxShadow: 'none',
                    backgroundColor: 'primary.light',
                    width: { md: 132, sm: 95 },
                    height: { lg: 40, sm: 33 },
                    // fontWeight: 500,
                    fontFamily: 'Whitney',
                    fontSize: { md: 16, sm: 14 },
                    letterSpacing: '0.1px',
                    ':hover': {
                      backgroundColor: '#479da6d6',
                    },
                  }}
                  startIcon={<Iconify icon="ic:outline-sim-card-download" />}
                >
                  Export
                </Button>
              )}
            </Stack>
          </Grid>
        )}
      </Grid>
    </StyledToolbar>
  );
}

SmartTabledToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  selected: PropTypes.array,
  onhandeSubmit: PropTypes.func,
  searchKey: PropTypes.string,
  exportCSV: PropTypes.array,
  onDateChange: PropTypes.func,
  columnsDetails: PropTypes.array,
  selectedColumns: PropTypes.array,
  handleColumnChange: PropTypes.func,
  isSearch: PropTypes.bool,
  isAdvancedFilter: PropTypes.bool,
  onSearchClick: PropTypes.func,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  onClickAdvancedFilter: PropTypes.func,
  showAdvancedFilter: PropTypes.bool,
  isGridSelection: PropTypes.bool,
  dateTypeList: PropTypes.array,
  onChangeAdvancedForm: PropTypes.func,
  advancedFormData: PropTypes.object,
  trackingPage: PropTypes.bool,
  userSelectedColumns: PropTypes.array,
  showFileUpload: PropTypes.bool,
  onFileUpload: PropTypes.func,
  isReplacementPage: PropTypes.bool,
  isDateRangePicker: PropTypes.bool,
  isExportEnable: PropTypes.bool,
};

// status filter
function StatusFilterMenu({ active, onStatusChange, originalRows, statusList, statusColumn, statusAllData }) {
  const StyledButton = styled(Button)(({ theme }) => ({
    borderRadius: 0,
    color: theme.palette.text.light,
    fontWeight: 500,
  }));
  const rawStatusList = statusList.length > 0 ? statusList : originalRows.map((el) => el[statusColumn]);
  if (statusAllData != '') {
    rawStatusList.unshift(statusAllData);
  }
  const finalStatusList = [...new Set(rawStatusList)];
  const buttonList = [];
  finalStatusList.forEach((item) =>
    buttonList.push(
      <StyledButton
        key={item}
        name={item}
        className={active === item && 'active'}
        onClick={onStatusChange}
        sx={{
          '&.active': {
            borderBottom: '3px solid',
            borderColor: 'primary.dark',
            color: 'primary.dark',
            lineHeight: '24px',
          },
          fontSize: { lg: 16, md: 16, sm: 14 },
          fontFamily: 'Whitney',
        }}
      >
        {item}
      </StyledButton>
    )
  );
  return (
    <Stack
      direction="row"
      spacing={3}
      sx={{
        borderBottom: 1,
        borderStyle: 'line',
        borderColor: 'secondary.gray',
        marginBottom: 2,
      }}
    >
      {buttonList}
    </Stack>
  );
}

StatusFilterMenu.propTypes = {
  active: PropTypes.string,
  onStatusChange: PropTypes.func,
  originalRows: PropTypes.array,
  statusList: PropTypes.array,
  statusColumn: PropTypes.string,
  statusAllData: PropTypes.string,
};

// Advanced filter
function AdvancedFilter({
  onChangeAdvancedForm,
  advancedFormData,
  statusSelect,
  carrierSelect,
  scannedStatus,
  firstScanEmailStatistics,
  onSubmitAdvancedFilter,
}) {
  const StyledDivider = styled(Divider)(({ theme }) => ({
    borderStyle: 'line',
    borderWidth: theme.spacing(0.1),
    margin: theme.spacing(1, 0),
  }));

  const { pathname } = useLocation();

  return (
    <Stack direction="column">
      <StyledDivider />
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: { lg: 'space-between', sm: 'start' },
          my: 1,
          // height:{sm: '500'}
        }}
      >
        <Grid
          container
          rowSpacing={{ lg: 1, sm: 1 }}
          columnSpacing={{ lg: 2.5, sm: 2.5 }}
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: { lg: 'space-between', sm: 'start' },
            my: 0,
            '& .MuiTableRow': { padding: '16px' },
          }}
        >
          <InputForm
            name="first_name"
            label="Customer First Name"
            onChangeAdvancedForm={onChangeAdvancedForm}
            advancedFormData={advancedFormData}
          />
          <DatePickerInput
            name="shipping_date"
            label="Shipped On"
            onChangeAdvancedForm={onChangeAdvancedForm}
            advancedFormData={advancedFormData}
          />
          <InputForm
            name="customer_email"
            label="Customer Email"
            onChangeAdvancedForm={onChangeAdvancedForm}
            advancedFormData={advancedFormData}
          />

          {pathname.includes('/dashboard/reports') ? (
            <SelectInput
              name="status"
              label="Status"
              onChangeAdvancedForm={onChangeAdvancedForm}
              advancedFormData={advancedFormData}
              listItem={statusSelect}
              defaultValue={
                pathname === '/dashboard/reports/preshipment'
                  ? 'Pre-Shipment'
                  : pathname === '/dashboard/reports/intransit'
                  ? 'In Transit'
                  : null // Set defaultValue to undefined if none of the conditions match
              }
              sx={{
                marginTop: { lg: 1, sm: 0.3, height: { lg: 48, md: 41, sm: 35 } },
                '&  .MuiFormControl-root > .MuiInputBase-root': { height: { lg: 48, md: 41, sm: 35 } },
              }}
            />
          ) : (
            <MultiSelectInput
              name="status"
              label="Status"
              onChangeAdvancedForm={onChangeAdvancedForm}
              advancedFormData={advancedFormData}
              listItem={statusSelect}
              sx={{
                marginTop: { lg: 1, sm: 0.3, height: { lg: 48, md: 41, sm: 35 } },
                '&  .MuiFormControl-root > .MuiInputBase-root': { height: { lg: 48, md: 41, sm: 35 } },
              }}
            />
          )}
          <InputForm
            name="last_name"
            label="Customer Last Name"
            onChangeAdvancedForm={onChangeAdvancedForm}
            advancedFormData={advancedFormData}
          />
          <DatePickerInput
            name="estimated_date"
            label="Estimated Delivery"
            onChangeAdvancedForm={onChangeAdvancedForm}
            advancedFormData={advancedFormData}
            futureDateEnable="true"
          />
          <InputForm
            name="order_id"
            label="Order Number"
            onChangeAdvancedForm={onChangeAdvancedForm}
            advancedFormData={advancedFormData}
          />
          <SelectInput
            name="carrier"
            label="Carriers"
            onChangeAdvancedForm={onChangeAdvancedForm}
            advancedFormData={advancedFormData}
            listItem={carrierSelect}
            sx={{
              marginTop: { lg: 1, sm: 0.3 },
              height: { lg: 40, md: 41, sm: 35 },
              '&  .MuiFormControl-root > .MuiInputBase-root': { height: { lg: 48, md: 41, sm: 35 } },
            }}
          />
          <InputForm
            name="shipping_city"
            label="City"
            onChangeAdvancedForm={onChangeAdvancedForm}
            advancedFormData={advancedFormData}
          />
          <InputForm
            name="shipping_state"
            label="State"
            onChangeAdvancedForm={onChangeAdvancedForm}
            advancedFormData={advancedFormData}
          />
          <InputForm
            name="zip_code"
            label="Zip Code"
            onChangeAdvancedForm={onChangeAdvancedForm}
            advancedFormData={advancedFormData}
          />
          <SelectInput
            name="scanned_status"
            label="Scanned Status"
            onChangeAdvancedForm={onChangeAdvancedForm}
            advancedFormData={advancedFormData}
            listItem={scannedStatus}
            sx={{
              marginTop: { lg: 1, sm: 0.3 },
              height: { lg: 40, md: 41, sm: 35 },
              '&  .MuiFormControl-root > .MuiInputBase-root': { height: { lg: 48, md: 41, sm: 35 } },
            }}
          />
          {!pathname.includes('/dashboard/reports') && (
            <SelectInput
              name="first_scan"
              label="First Scan Email Statistics"
              onChangeAdvancedForm={onChangeAdvancedForm}
              advancedFormData={advancedFormData}
              listItem={firstScanEmailStatistics}
              sx={{
                marginTop: { lg: 1, sm: 0.3 },
                height: { lg: 40, md: 41, sm: 35 },
                '&  .MuiFormControl-root > .MuiInputBase-root': { height: { lg: 48, md: 41, sm: 35 } },
              }}
            />
          )}
          <Box
            sx={{
              display: { md: 'flex', sm: 'flex' },
              width: '100%',
              pt: 4,
              flexDirection: 'row-reverse',
            }}
          >
            <Button
              size="large"
              type="button"
              variant="contained"
              onClick={onSubmitAdvancedFilter}
              sx={{
                bgcolor: 'primary.light',
                justifyContent: 'center',
                boxShadow: 'none',
                width: 157,
                height: 46,
              }}
            >
              Continue
            </Button>
          </Box>
        </Grid>
      </Box>
    </Stack>
  );
}

AdvancedFilter.propTypes = {
  onChangeAdvancedForm: PropTypes.func,
  advancedFormData: PropTypes.object,
  statusSelect: PropTypes.array,
  carrierSelect: PropTypes.array,
  scannedStatus: PropTypes.array,
  firstScanEmailStatistics: PropTypes.array,
  onSubmitAdvancedFilter: PropTypes.func,
};

// main table
export default function SmartTable({
  originalRows,
  isSearch,
  defaultColumn,
  statusColumn,
  isStatusMenu,
  isRowSelectable,
  refreshAction,
  statusList,
  statusAllData,
  getDateRange,
  onRefreshClicked,
  onExpandhandler,
  expandedRow,
  isAdvancedFilter,
  totelItemCount,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  rowsPerPage,
  onhandeSeachSubmit,
  onBulkOrderSearch,
  searchKey,
  onSearchClick,
  showLoader,
  startDate,
  endDate,
  onChangeAdvancedForm,
  advancedFormData,
  carrierSelect,
  statusSelect,
  scannedStatus,
  firstScanEmailStatistics,
  onSubmitAdvancedFilter,
  exapndRowIntputData,
  trackingInfoRef,
  showAdvancedFilter,
  onClickAdvancedFilter,
  onStatusChange,
  statusToShow,
  isGridSelection,
  dateTypeList,
  exportButtonClicked,
  trackingPage,
  userSelectedColumns,
  handleColumnSubmit,
  modalLoader,
  columnsToRemove,
  sortOrder,
  showToolbar = true,
  replaceAction = false,
  onReplaceHandler,
  rowHover = true,
  style,
  showFileUpload = false,
  onFileUpload,
  cxAction = false,
  isReplacementPage = false,
  isDateRangePicker = false,
  isExportEnable = false,
}) {
  //----------------------------------------------------------------------------

  const [rows, setRows] = useState([]);
  const [columnsToShow, setColumsToShow] = useState([]);
  // const [selectedColumns, setSelectedColumns] = useState(userSelectedColumns);
  const [order, setOrder] = useState('asc');
  const [isSortable, setIsSortable] = useState(false);
  const [orderBy, setOrderBy] = useState(defaultColumn);
  const [selected, setSelected] = useState([]);
  const [reportToExport, setReportToExport] = useState(rows);
  const [rawColumnList, setRawCloumnList] = useState([]);

  useEffect(() => {
    setRows(originalRows);
    setReportToExport(originalRows);
    if (userSelectedColumns?.length > 0) {
      setColumsToShow(userSelectedColumns);
    } else if (originalRows.length > 0) {
      const filteredCloumns = Object.keys(originalRows[0]).filter((name) => !columnsToRemove?.includes(name));
      setColumsToShow(filteredCloumns);
    } else {
      setColumsToShow([]);
    }
    setRawCloumnList(
      originalRows.length > 0 ? Object.keys(originalRows[0]).filter((name) => !columnsToRemove?.includes(name)) : []
    );
    setIsSortable(false);
    setSelected([]);
  }, [originalRows, userSelectedColumns]);

  useEffect(() => {
    setSelected([]);
  }, [statusToShow]);
  // Styles
  const StyledTableBody = styled(TableBody)(({ theme }) => ({
    color: theme.palette.common.black,
    fontSize: { sm: 14, lg: 16 },
    fontFamily: 'Whitney',
  }));

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    color: theme.palette.common.black,
    fontSize: { sm: 14, lg: 16 },
    fontFamily: 'Whitney',
  }));

  const StyledTablePagination = styled(TablePagination)(({ theme }) => ({
    color: theme.palette.common.black,
    '& .MuiInputBase-root': {
      border: '1px solid #757575',
      borderRadius: 4,
    },
    '& .MuiPaper-root': {
      color: theme.palette.common.black,
    },
  }));

  // Create columns from rows
  const columns = [];
  columnsToShow.forEach((element) => {
    if (element !== defaultColumn) {
      const obj = {};
      obj.id = element;
      obj.label = titleCase(element);
      columns.push(obj);
    }
  });

  // columns for toolbar
  const toolbarColumns = [];
  rawColumnList.sort().forEach((element) => {
    if (element !== defaultColumn) {
      const obj = {};
      obj.id = element;
      obj.label = titleCase(element);
      toolbarColumns.push(obj);
    }
  });

  const handleRequestSort = (event, property) => {
    setIsSortable(true);
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // Select single row
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n[defaultColumn]);
      const recordsToExport = [];
      newSelected.forEach((item) => {
        const result = originalRows.filter((obj) => obj[defaultColumn] === item);
        recordsToExport.push(result[0]);
      });
      setSelected(newSelected);
      setReportToExport(recordsToExport);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    const recordsToExport = [];
    newSelected.forEach((item) => {
      const result = originalRows.filter((obj) => obj[defaultColumn] === item);
      recordsToExport.push(result[0]);
    });
    setSelected(newSelected);
    setReportToExport(recordsToExport);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  // Rows to show on table
  const visibleRows = useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy), isSortable).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, rows]
  );
  return (
    <>
      {showToolbar && (
        <SmartTabledToolbar
          key="TableToolBar"
          numSelected={selected.length}
          selected={selected}
          originalRows={originalRows}
          onhandeSubmit={onhandeSeachSubmit}
          searchKey={searchKey}
          exportCSV={reportToExport}
          onDateChange={getDateRange}
          columnsDetails={removeItemOnce(rawColumnList, defaultColumn)}
          handleColumnSubmit={handleColumnSubmit}
          isSearch={isSearch}
          isAdvancedFilter={isAdvancedFilter}
          onSearchClick={onSearchClick}
          startDate={startDate}
          endDate={endDate}
          onClickAdvancedFilter={onClickAdvancedFilter}
          showAdvancedFilter={showAdvancedFilter}
          isGridSelection={isGridSelection}
          dateTypeList={dateTypeList}
          onChangeAdvancedForm={onChangeAdvancedForm}
          advancedFormData={advancedFormData}
          exportButtonClicked={exportButtonClicked}
          trackingPage={trackingPage}
          userSelectedColumns={userSelectedColumns}
          showFileUpload={showFileUpload}
          onFileUpload={onFileUpload}
          isReplacementPage={isReplacementPage}
          isDateRangePicker={isDateRangePicker}
          isExportEnable={isExportEnable}
        />
      )}

      {showAdvancedFilter && (
        <AdvancedFilter
          onChangeAdvancedForm={onChangeAdvancedForm}
          advancedFormData={advancedFormData}
          carrierSelect={carrierSelect}
          statusSelect={statusSelect}
          scannedStatus={scannedStatus}
          firstScanEmailStatistics={firstScanEmailStatistics}
          onSubmitAdvancedFilter={onSubmitAdvancedFilter}
        />
      )}
      {statusColumn && isStatusMenu && (
        <StatusFilterMenu
          key="StatusFilterMenu"
          active={statusToShow}
          onStatusChange={onStatusChange}
          originalRows={originalRows}
          statusList={statusList}
          statusColumn={statusColumn}
          statusAllData={statusAllData}
        />
      )}
      {showLoader ? (
        <Stack direction="row" alignItems="center" justifyContent="center">
          <Typography variant="h5" sx={{ color: 'primary.main' }}>
            Loading...
          </Typography>
        </Stack>
      ) : rows.length > 0 ? (
        <Card sx={{ width: '100%', borderRadius: 1 }}>
          {selected?.length > 0 && replaceAction && (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              bgcolor="secondary.lighter"
              sx={{ padding: '1rem' }}
            >
              <Typography variant="h6" sx={{ color: 'common.black' }}>
                {selected.length} Selected
              </Typography>
              <div style={{ display: 'flex', gap: '5px' }}>
                <Button
                  type="button"
                  variant="contained"
                  onClick={() => onReplaceHandler('approve', selected)}
                  sx={{
                    display: 'flex',
                    width: { lg: '81px', md: '81px', sm: '63px' },
                    height: { lg: '30px', sm: '30px' },
                    padding: '0.5rem 1.75rem 0.625rem 1.813rem',
                    justifyContent: 'center',
                    alignItems: 'center',
                    boxShadow: 'none',
                    flexShrink: 0,
                    bgcolor: 'primary.light',
                    textAlign: 'center',
                    fontFamily: 'Whitney',
                    fontSize: { lg: '14px', sm: '14px' },
                    fontWeight: 500,
                    ':hover': {
                      backgroundColor: '#479da6d6',
                    },
                  }}
                >
                  Approve
                </Button>
                <Button
                  type="button"
                  variant="contained"
                  onClick={() => onReplaceHandler('reject', selected)}
                  sx={{
                    display: 'flex',
                    width: { lg: '81px', md: '81px', sm: '63px' },
                    height: { lg: '30px', sm: '30px' },
                    padding: '0.5rem 1.75rem 0.625rem 1.813rem',
                    justifyContent: 'center',
                    alignItems: 'center',
                    boxShadow: 'none',
                    flexShrink: 0,
                    color: 'primary.main',
                    bgcolor: 'primary.lightbg',
                    textAlign: 'center',
                    fontFamily: 'Whitney',
                    fontSize: { lg: '14px', sm: '14px' },
                    fontWeight: 500,
                    ':hover': {
                      backgroundColor: '#dadadad1',
                    },
                  }}
                >
                  Reject
                </Button>
              </div>
            </Stack>
          )}
          <Paper sx={{ width: '100%', mb: 2, boxShadow: 'none' }}>
            <TableContainer sx={{ width: 'auto' }}>
              <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
                <SmartTabledHead
                  numSelected={selected.length}
                  selected={selected}
                  order={order}
                  visibleRows={visibleRows}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  columnHeader={columns}
                  defaultColumn={defaultColumn}
                  isRowSelectable={isRowSelectable}
                  refreshAction={refreshAction}
                  onRefreshClicked={onRefreshClicked}
                  sortOrder={sortOrder}
                  replaceAction={replaceAction}
                  cxAction={cxAction}
                />
                <StyledTableBody className="main">
                  {visibleRows.map((row, index) => {
                    const isItemSelected = isSelected(row[defaultColumn]);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <>
                        <TableRow
                          hover={false}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row[defaultColumn]}
                          selected={isItemSelected}
                          sx={style}
                          // onClick={(event) => onExpandhandler(event, row[defaultColumn], index)}
                        >
                          {isRowSelectable ? (
                            <>
                              <StyledTableCell
                                padding="checkbox"
                                onClick={(event) => handleClick(event, row[defaultColumn])}
                              >
                                <Checkbox
                                  color="primary"
                                  checked={isItemSelected}
                                  inputProps={{
                                    'aria-labelledby': labelId,
                                  }}
                                />
                              </StyledTableCell>
                              <StyledTableCell
                                onClick={(event) => onExpandhandler(event, row[defaultColumn], defaultColumn)}
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="checkbox"
                                align="left"
                                sx={{
                                  color: rowHover && 'primary.dark',
                                  fontSize: '1rem',
                                  cursor: 'pointer',
                                  textDecoration: 'underline',
                                }}
                              >
                                {row[defaultColumn]}
                              </StyledTableCell>
                            </>
                          ) : (
                            <StyledTableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="checkbox"
                              align="left"
                              sx={{ color: rowHover && 'primary.dark', paddingLeft: 5, fontSize: '1rem' }}
                            >
                              {row[defaultColumn]}
                            </StyledTableCell>
                          )}
                          {columnsToShow.map((item) =>
                            item !== defaultColumn ? (
                              <StyledTableCell
                                onClick={(event) => onExpandhandler(event, row[item], item)}
                                ref={(e) => (trackingInfoRef ? (trackingInfoRef.current[index] = e) : null)}
                                key={item}
                                sx={
                                  item === statusColumn
                                    ? {
                                        color: `status.${removeTitleCase(row[item])}`,
                                        fontWeight: 600,
                                        width: 300,
                                        fontSize: '1rem',
                                      }
                                    : {
                                        fontSize: '1rem',
                                        color: rowHover && item == 'customer_id' && 'primary.dark',
                                        cursor: item == 'customer_id' && 'pointer',
                                        textDecoration: item == 'customer_id' && 'underline',
                                      }
                                }
                                align="left"
                              >
                                {titleCase(row[item])}{' '}
                              </StyledTableCell>
                            ) : null
                          )}{' '}
                          {cxAction && (
                            <StyledTableCell
                              hover
                              align="center"
                              sx={{
                                width: '169px',
                                height: '62px',
                                position: 'sticky',
                                right: 161,
                                backgroundColor: '#fff',
                              }}
                            >
                              <Button
                                type="button"
                                variant="contained"
                                onClick={() =>
                                  onReplaceHandler('ticket', replaceAction ? row['order_id'] : row[defaultColumn])
                                }
                                disabled={
                                  replaceAction
                                    ? row['ticket_id']
                                    : row[statusColumn] == 'Alert'
                                    ? !row['is_create_ticket']
                                    : true
                                }
                                sx={{
                                  display: 'flex',
                                  width: { lg: '112px', md: '112px', sm: '112px' },
                                  height: { lg: '30px', sm: '30px' },
                                  padding: '0.5rem 1rem 0.625rem 1rem',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  boxShadow: 'none',
                                  flexShrink: 0,
                                  bgcolor: 'primary.light',
                                  textAlign: 'center',
                                  fontFamily: 'Whitney',
                                  fontSize: { lg: '14px', sm: '14px' },
                                  fontWeight: 500,
                                  ':hover': {
                                    backgroundColor: '#479da6d6',
                                  },
                                }}
                              >
                                Create ticket
                              </Button>
                            </StyledTableCell>
                          )}
                          {refreshAction && (
                            <StyledTableCell
                              hover
                              align="center"
                              sx={{
                                width: '169px',
                                height: '62px',
                                position: 'sticky',
                                right: 0,
                                backgroundColor: '#fff',
                              }}
                            >
                              <IconButton
                                size="small"
                                color="secondary"
                                sx={{
                                  marginRight: '1rem',
                                }}
                                onClick={(event) => onExpandhandler(event, row[defaultColumn], defaultColumn)}
                                aria-label="info"
                              >
                                <Iconify
                                  sx={{ color: 'common.black' }}
                                  icon="mdi:information-outline"
                                  width={25}
                                ></Iconify>
                              </IconButton>

                              <IconButton
                                size="small"
                                color="secondary"
                                disabled={row[statusColumn] === 'Delivered' || row['scanned_status'] === 'Delivered'}
                                sx={{
                                  opacity:
                                    row[statusColumn] == 'Delivered' || row['scanned_status'] == 'Delivered' ? 0.3 : 1,
                                }}
                                onClick={(event) => onRefreshClicked(event, row[defaultColumn], false)}
                                aria-label="refresh"
                              >
                                <img width="18px" src="/assets/icons/table/refresh.svg" alt="" />
                              </IconButton>
                            </StyledTableCell>
                          )}
                          {replaceAction && (
                            <StyledTableCell
                              hover
                              align="center"
                              sx={{
                                width: '169px',
                                height: '62px',
                                position: 'sticky',
                                right: 0,
                                backgroundColor: '#fff',
                              }}
                            >
                              <div style={{ display: 'flex', gap: '5px' }}>
                                <Button
                                  type="button"
                                  variant="contained"
                                  onClick={() => onReplaceHandler('approve', row[defaultColumn])}
                                  sx={{
                                    display: 'flex',
                                    width: { lg: '81px', md: '81px', sm: '63px' },
                                    height: { lg: '30px', sm: '30px' },
                                    padding: '0.5rem 1.75rem 0.625rem 1.813rem',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    boxShadow: 'none',
                                    flexShrink: 0,
                                    bgcolor: 'primary.light',
                                    textAlign: 'center',
                                    fontFamily: 'Whitney',
                                    fontSize: { lg: '14px', sm: '14px' },
                                    fontWeight: 500,
                                    ':hover': {
                                      backgroundColor: '#479da6d6',
                                    },
                                  }}
                                >
                                  Approve
                                </Button>
                                <Button
                                  type="button"
                                  variant="contained"
                                  onClick={() => onReplaceHandler('reject', row[defaultColumn])}
                                  sx={{
                                    display: 'flex',
                                    width: { lg: '81px', md: '81px', sm: '63px' },
                                    height: { lg: '30px', sm: '30px' },
                                    padding: '0.5rem 1.75rem 0.625rem 1.813rem',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    boxShadow: 'none',
                                    flexShrink: 0,
                                    color: 'primary.main',
                                    bgcolor: 'primary.lightbg',
                                    textAlign: 'center',
                                    fontFamily: 'Whitney',
                                    fontSize: { lg: '14px', sm: '14px' },
                                    fontWeight: 500,
                                    ':hover': {
                                      backgroundColor: '#dadadad1',
                                    },
                                  }}
                                >
                                  Reject
                                </Button>
                              </div>
                            </StyledTableCell>
                          )}
                        </TableRow>
                        {expandedRow && (
                          <TableRow key={`${expandedRow}-expand-column`} sx={{ bgcolor: '#F6FAFC' }}>
                            <TableCell xs="auto" colSpan={24} sx={{ padding: 0, border: '0px' }}>
                              <Collapse in={expandedRow === row[defaultColumn]} timeout="auto" unmountOnExit>
                                <CollapseTableRow
                                  showLoader={modalLoader}
                                  intputData={exapndRowIntputData}
                                  onExpandhandler={onExpandhandler}
                                />
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        )}
                      </>
                    );
                  })}
                </StyledTableBody>
              </Table>
            </TableContainer>
            <StyledTablePagination
              rowsPerPageOptions={[10, 50, 100]}
              component="div"
              count={totelItemCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Card>
      ) : (
        <Card
          sx={{
            width: '100%',
            boxShadow: 'none',
            minHeight: 500,
            display: 'flex',
            borderRadius: 1,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Stack direction="row" alignItems="center" justifyContent="center">
            <Typography variant="h5" sx={{ color: 'primary.main' }}>
              No data found
            </Typography>
          </Stack>
        </Card>
      )}
    </>
  );
}

SmartTable.propTypes = {
  originalRows: PropTypes.array,
  isSearch: PropTypes.bool,
  defaultColumn: PropTypes.string,
  statusColumn: PropTypes.string,
  statusList: PropTypes.array,
  statusAllData: PropTypes.string,
  getDateRange: PropTypes.func,
  onRefreshClicked: PropTypes.func,
  onExpandhandler: PropTypes.func,
  expandedRow: PropTypes.string,
  isAdvancedFilter: PropTypes.bool,
  totelItemCount: PropTypes.number,
  page: PropTypes.number,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  rowsPerPage: PropTypes.number,
  onhandeSeachSubmit: PropTypes.func,
  searchKey: PropTypes.string,
  onSearchClick: PropTypes.func,
  showLoader: PropTypes.bool,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  onChangeAdvancedForm: PropTypes.func,
  advancedFormData: PropTypes.object,
  carrierSelect: PropTypes.array,
  statusSelect: PropTypes.array,
  scannedStatus: PropTypes.array,
  firstScanEmailStatistics: PropTypes.array,
  onSubmitAdvancedFilter: PropTypes.func,
  exapndRowIntputData: PropTypes.object,
  trackingInfoRef: PropTypes.object,
  isStatusMenu: PropTypes.bool,
  isRowSelectable: PropTypes.bool,
  refreshAction: PropTypes.bool,
  showAdvancedFilter: PropTypes.bool,
  onClickAdvancedFilter: PropTypes.func,
  onStatusChange: PropTypes.func,
  statusToShow: PropTypes.string,
  isGridSelection: PropTypes.bool,
  dateTypeList: PropTypes.array,
  exportButtonClicked: PropTypes.func,
  trackingPage: PropTypes.bool,
  userSelectedColumns: PropTypes.array,
  handleColumnSubmit: PropTypes.func,
  modalLoader: PropTypes.bool,
  columnsToRemove: PropTypes.array,
  sortOrder: PropTypes.func,
  replaceAction: PropTypes.bool,
  onReplaceHandler: PropTypes.func,
  showFileUpload: PropTypes.bool,
  onFileUpload: PropTypes.func,
  cxAction: PropTypes.bool,
  isReplacementPage: PropTypes.bool,
  isDateRangePicker: PropTypes.bool,
  isExportEnable: PropTypes.bool,
};
